import { memo } from "react";
import { Icon, IconProps, useToken } from "@chakra-ui/react";

export const FaceIDDuoImage = memo(
  ({ accent = "brand.accent", ...props }: { accent?: string } & IconProps) => {
    const accentToken = useToken("colors", accent as string);

    return (
      <Icon width="138" height="107" viewBox="0 0 138 107" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8V24H138V8C138 3.58172 134.418 0 130 0H8ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15ZM27 12C27 13.6569 25.6569 15 24 15C22.3431 15 21 13.6569 21 12C21 10.3431 22.3431 9 24 9C25.6569 9 27 10.3431 27 12ZM36 15C37.6569 15 39 13.6569 39 12C39 10.3431 37.6569 9 36 9C34.3431 9 33 10.3431 33 12C33 13.6569 34.3431 15 36 15Z"
          fill="#242824"
        />
        <path
          d="M0 27H138V99C138 103.418 134.418 107 130 107H8C3.58172 107 0 103.418 0 99V27Z"
          fill="#242824"
        />
        <rect
          x="24"
          y="39"
          width="90"
          height="50"
          rx="3"
          fill="white"
          fillOpacity="0.08"
        />
        <path
          d="M36.983 61.4995H38.8882C39.4416 61.4995 39.8714 61.0021 39.8714 60.4388L39.8726 54.2389C39.8726 53.6418 40.3024 53.1782 40.8558 53.1782H46.5728C47.1262 53.1782 47.556 52.6808 47.556 52.1174V50.0607C47.556 49.4637 47.095 49 46.5728 49H40.8859C38.1824 49 36 51.3546 36 54.239V60.4067C36 61.0025 36.4608 61.4995 36.983 61.4995Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M64.9883 67.3335H63.0881C62.5361 67.3335 62.1074 67.799 62.1074 68.3262V74.0983C62.1074 74.6571 61.6788 75.091 61.1268 75.091H55.4247C54.8727 75.091 54.444 75.5565 54.444 76.0837V78.0073C54.444 78.5661 54.9038 79 55.4247 79H61.1268C63.7933 79 66 76.7964 66 74.0669V68.3581C65.9689 67.7993 65.5092 67.3335 64.9883 67.3335Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M61.1129 49.0001H55.4268C54.8733 49.0001 54.4435 49.4988 54.4435 50.0637V52.1246C54.4435 52.7233 54.9046 53.1882 55.4268 53.1882H61.1443C61.6977 53.1882 62.1275 53.6531 62.1275 54.2518V60.4362C62.1275 61.0349 62.5886 61.4998 63.1108 61.4998H65.0162C65.5697 61.4998 65.9995 61.0011 65.9995 60.4362V54.2532C65.9995 51.361 63.8165 49.0001 61.1129 49.0001Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M46.5442 75.0697H40.8734C40.3214 75.0697 39.8927 74.6369 39.8927 74.0796V68.3242C39.8927 67.767 39.4329 67.3342 38.912 67.3342L36.9807 67.333C36.4287 67.333 36 67.7972 36 68.323V74.0797C36 76.7717 38.1769 78.9995 40.8732 78.9995H46.5753C47.1273 78.9995 47.556 78.5353 47.556 78.0095V76.091C47.556 75.5036 47.0962 75.0697 46.5442 75.0697Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M45 60.3077C45 61.5643 46.0226 62.6154 47.3077 62.6154C48.5643 62.6154 49.6154 61.5928 49.6154 60.3077C49.6154 59.0511 48.5928 58 47.3077 58C46.0226 57.9989 45 59.0215 45 60.3077Z"
          fill={accentToken}
        />
        <path
          d="M52.3846 60.3077C52.3846 61.5643 53.4072 62.6154 54.6923 62.6154C55.9489 62.6154 57 61.5928 57 60.3077C57 59.0511 55.9774 58 54.6923 58C53.4072 57.9989 52.3846 59.0215 52.3846 60.3077Z"
          fill={accentToken}
        />
        <path
          d="M47.2648 65.8577C47.7983 65.2269 48.6868 65.2269 49.2193 65.8577C50.1841 66.9985 51.8343 66.9985 52.7992 65.8577C53.3327 65.2269 54.2212 65.2269 54.7537 65.8577C55.2872 66.4885 55.2872 67.539 54.7537 68.1686C53.7383 69.3399 52.4184 70 50.9963 70C49.5743 70 48.2544 69.3399 47.2649 68.1686C46.7066 67.539 46.7065 66.4885 47.2648 65.8577Z"
          fill={accentToken}
        />
        <path
          d="M78 64C78 62.8954 78.8954 62 80 62H86C87.1046 62 88 62.8954 88 64C88 65.1046 87.1046 66 86 66H80C78.8954 66 78 65.1046 78 64Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M90 64C90 62.8954 90.8954 62 92 62H100C101.105 62 102 62.8954 102 64C102 65.1046 101.105 66 100 66H92C90.8954 66 90 65.1046 90 64Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M78 71C78 69.8954 78.8954 69 80 69H91C92.1046 69 93 69.8954 93 71C93 72.1046 92.1046 73 91 73H80C78.8954 73 78 72.1046 78 71Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M95 71C95 69.8954 95.8954 69 97 69C98.1046 69 99 69.8954 99 71C99 72.1046 98.1046 73 97 73C95.8954 73 95 72.1046 95 71Z"
          fill="white"
          fillOpacity="0.12"
        />
        <path
          d="M78 57C78 55.8954 78.8954 55 80 55H100C101.105 55 102 55.8954 102 57C102 58.1046 101.105 59 100 59H80C78.8954 59 78 58.1046 78 57Z"
          fill="white"
          fillOpacity="0.12"
        />
      </Icon>
    );
  },
);

FaceIDDuoImage.displayName = "FaceIDDuoImage";
