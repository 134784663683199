import { memo } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const QuestionIcon = memo((props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM9.30625 9.16563C9.55312 8.46875 10.2156 8 10.9563 8H12.7781C13.8687 8 14.75 8.88438 14.75 9.97188C14.75 10.6781 14.3719 11.3312 13.7594 11.6844L12.75 12.2625C12.7437 12.6687 12.4094 13 12 13C11.5844 13 11.25 12.6656 11.25 12.25V11.8281C11.25 11.5594 11.3938 11.3125 11.6281 11.1781L13.0125 10.3844C13.1594 10.3 13.25 10.1437 13.25 9.975C13.25 9.7125 13.0375 9.50313 12.7781 9.50313H10.9563C10.85 9.50313 10.7563 9.56875 10.7219 9.66875L10.7094 9.70625C10.5719 10.0969 10.1406 10.3 9.75313 10.1625C9.36563 10.025 9.15938 9.59375 9.29688 9.20625L9.30937 9.16875L9.30625 9.16563ZM11 15C11 14.7348 11.1054 14.4804 11.2929 14.2929C11.4804 14.1054 11.7348 14 12 14C12.2652 14 12.5196 14.1054 12.7071 14.2929C12.8946 14.4804 13 14.7348 13 15C13 15.2652 12.8946 15.5196 12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071C11.1054 15.5196 11 15.2652 11 15Z"
      fill="currentColor"
    />
  </Icon>
));
